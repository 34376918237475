import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const submitForm = createAsyncThunk(
  'root/submitForm',
  async (formData, { getState }) => {

    try {
      const formData = getState();

      const data = {
        ...formData.form.form,
        icBudget: formData.form.form.icBudget ? formData.form.form.icBudget : 0,
      };

      const zapierResponse = await axios.post(
        `https://hook.eu2.make.com/y27jfoki2rpc5w96777d6x3bngiqjs4m`,
        data,
        {
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      );
    } catch(error) {
      console.log(error);
    }

    try {
      const formData = getState();

      const data = {
        ...formData.form.form,
        icBudget: formData.form.form.icBudget ? formData.form.form.icBudget : 0,
      };

      axios.defaults.headers.common['Authorization'] = 'Basic cHpoOlBaSDE4';
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT}customers/inquiry`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const rootSlice = createSlice({
  name: 'root',
  initialState: {
    form: {
      icStart: '',
      icZipcode: '',
      icPersonsInHouse: '',
      icPersonsCare: '',
      icExtraRoom: '',
      icPatientAgrees: true,
      icBudget: 0,
      icBudgetUnclear: false,
      salutation: '',
      firstname: '',
      lastname: '',
      phoneType: 'Tel. privat',
      phoneCountryCode: '0049',
      phonePrefix: '',
      phoneNumber: '',
      email: '',
      notes: '',
      privacy: false,
      contactedThrough: '',
      contactedThroughOther: '',
      parkingSpaceNurse: '',
      currentUrl: ''
    },
  },
  reducers: {
    setForm: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
  },
});

export const formReducer = rootSlice.reducer;

export const { setForm } = rootSlice.actions;
