import { createSlice } from '@reduxjs/toolkit';

const ebSlice = createSlice({
  name: 'eb',
  initialState: {
    token: null,
  },
  reducers: {
    setEBToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const ebReducer = ebSlice.reducer;

export const { setEBToken } = ebSlice.actions;
