import { createSlice } from '@reduxjs/toolkit';

export const WARNING_MODE = {
  OFF: 'off',
  EXTRA_ROOM: 'extraRoom',
  SERVER_ERROR: 'serverError',
};

const stepSlice = createSlice({
  name: 'step',
  initialState: {
    progress: 1,
    warning: {
      mode: WARNING_MODE.OFF,
      payload: 'Probieren Sie es nochmal',
    },
  },
  reducers: {
    nextStep: (state) => {
      state.progress += 1;
    },
    stepBack: (state) => {
      state.progress -= 1;
    },
    showWarning: (state, action) => {
      state.warning = {
        mode:
          (action && action.payload && action.payload.mode) || WARNING_MODE.OFF,
        payload:
          (action && action.payload && action.payload.payload) ||
          'Probieren Sie es nochmal',
      };
    },
  },
});

export const stepReducer = stepSlice.reducer;

export const { nextStep, stepBack, showWarning } = stepSlice.actions;
