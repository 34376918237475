import React from "react";
import { showWarning, WARNING_MODE } from "../stepSlice";
import back from "../img/back.svg";
import { useDispatch } from "react-redux";

const Warning = ({ mode, payload }) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(showWarning());
  };

  return (
    <div>
      <div className="ka-warning__container">
        <div className="ka-warning__decoration">!</div>

        {mode === WARNING_MODE.SERVER_ERROR && (
          <p className={"center"}>{payload}</p>
        )}

        {mode === WARNING_MODE.EXTRA_ROOM && (
          <p className={"center"}>
            Sehr geehrter Interessent, bitte haben Sie Verständnis dafür, dass
            wir Ihnen unsere Dienstleistung{" "}
            <strong>unter dieser Voraussetzung nicht anbieten können</strong> .
            Ein separates Zimmer als Rückzugsort für die Betreuungskraft ist
            zwingende Voraussetzung für die Betreuung im häuslichen Umfeld. Wir
            wünschen Ihnen für die Versorgung Ihres lieben Angehörigen alles
            erdenklich Gute.
            <br />
            <br />
            <strong>Ihr Team von Pflege zu Hause Küffel</strong>
          </p>
        )}
      </div>

      <div className="ka-step__buttons">
        {/*Back*/}
        <button onClick={handleBack} className="ka-button ka-button__back">
          <img
            src={back}
            className="is_svg"
            alt="✓"
            width="23px"
            height="23px"
          />
          <span>Zurück</span>
        </button>
      </div>
    </div>
  );
};

export default Warning;
