import React, { useEffect } from "react";
import successImg from "../img/success.svg";
import { useSelector } from "react-redux";
import axios from "axios";

const SubmitSuccess = () => {
  const ebtoken = useSelector((state) => state.eb.token);
  const link = `${process.env.REACT_APP_EB_URL}${ebtoken}`;

  useEffect(() => {
    setTimeout(function () {
      document
        .getElementById("ka-redesign-container")
        .scrollIntoView({ behavior: "smooth" });
    }, 250);
  }, []);

  return (
    <div className="ka-step__answer success_submit">
      <img src={successImg} alt="Icon" />
      <p className="kurzanfrage_headline"> Vielen Dank für Ihre Anfrage</p>
      <p className="kurzanfrage_subline">
        {" "}
        
        Ihr Kundenberater wird sich in Kürze persönlich bei Ihnen melden. Selbstverständlich finden wir auch für Sie eine gut geeignete Betreuungskraft.{" "}
      </p>
      {ebtoken ? (
        <>
          <p className="kurzanfrage_content">
            Damit wir schnellstmöglich mit der Suche beginnen können, benötigen wir von Ihnen weitere Angaben zum Betreuungsbedarf. Bitte machen Sie Ihre Angaben zur Betreuungssituation in unserem Erhebungsbogen und nutzen Sie dazu den unten stehenden Link.
          </p>
          <p className="kurzanfrage_content">
            Hinweis: Sollte es sich um mehr als eine betreuungsbedürftige Person handeln, so möchten wir Sie freundlich bitten, die Angaben im Erhebungsbogen zunächst nur für eine Person zu machen.
          </p>
          <p className="kurzanfrage_content">
            Alle erforderlichen Details zu Ihrer Betreuungssituation werden nachfolgend umfassend telefonisch mit Ihnen geklärt.
          </p>
          <div className="ka-step__buttons">
            <button
              className="ka-button ka-button__next-step"
              onClick={(e) => {
                e.preventDefault();
                e.target.innerHTML = "Ihre Anfrage wird verarbeitet...";
                const now = new Date();
                axios
                  .patch(`${process.env.REACT_APP_API_ROOT}customers/form`, {
                    token: ebtoken.replace('%7C', '|'),
                    questionnaireSentAt: now.toISOString(),
                  })
                  .then((resp) => {
                    window.location.href = link;
                  })
                  .catch((err) => {
                    console.error(err);
                    window.location.href = link;
                  });
              }}
            >
              <span>Erhebungsbogen ausfüllen</span>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubmitSuccess;
