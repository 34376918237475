import React from "react";
import "./css/style.css";
import "./css/hph.css";
import "./css/redesign.css";
// import ornament from './img/question-headline-ornament.svg';
import Question1 from "./components/Question1";
// import Question2 from "./components/Question2";
// import Question3 from "./components/Question3";
// import Question4 from "./components/Question4";
// import Question5 from "./components/Question5";
// import Question6 from "./components/Question6";
// import Q7PersonalData from "./components/Q7PersonalData";
import { useSelector } from "react-redux";
import SubmitSuccess from "./components/SubmitSuccess";
import Warning from "./components/Warning";
import { WARNING_MODE } from "./stepSlice";
// import packageJSON from '../package.json';

const App = () => {
  const { progress, warning } = useSelector((state) => state.step);

  return (
    <div id="ka-redesign-container" className="ka-container">

      {warning.mode === WARNING_MODE.OFF ? (
        <div className="ka-step--wrapper">

          {progress === 1 && <Question1 />}
          {/* {progress === 2 && <Question2 />}
          {progress === 3 && <Question3 />}
          {progress === 4 && <Question4 />}
          {progress === 5 && <Question5 />}
          {progress === 6 && <Question6 />}
          {progress === 7 && <Q7PersonalData />} */}
          {progress === 2 && <SubmitSuccess />}
        </div>
      ) : (
        <div>
          <Warning mode={warning.mode} payload={warning.payload} />
        </div>
      )}
    </div>
  );
};

export default App;
