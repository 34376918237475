import { configureStore } from "@reduxjs/toolkit";
import { formReducer } from "./rootSlice";
import { stepReducer } from "./stepSlice";
import { ebReducer } from "./ebSlice";

export const store = configureStore({
  reducer: {
    form: formReducer,
    step: stepReducer,
    eb: ebReducer
  },
});
